import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faCheckCircle,
  faEnvelope,
  faExclamationCircle,
  faMobile,
  faPhone,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'

export const ArrowRight = props => <Icon {...props} icon={faArrowRight} />
export const CheckCircle = props => <Icon {...props} icon={faCheckCircle} />
export const Envelope = props => <Icon {...props} icon={faEnvelope} />
export const ExclamationCircle = props => <Icon {...props} icon={faExclamationCircle} />
export const Mobile = props => <Icon {...props} icon={faMobile} />
export const Phone = props => <Icon {...props} icon={faPhone} />
export const TimesCircle = props => <Icon {...props} icon={faTimesCircle} />
